import { inject, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';

export const API_TOKEN = new InjectionToken<Observable<string | null>>(
  'the logged in user token',
  {
    providedIn: 'root',
    factory: () => {
      return inject(StorageService).getToken();
    },
  }
);
