import { UserAttributes } from '@escalate-backoffice/core/api-types';
import { createAdapter } from '@state-adapt/core';
import { AuthStatus } from './auth-status.adapter';

export interface AuthState {
  user: UserAttributes | null;
  status: AuthStatus | null;
  isLoggedIn?: boolean;
}

export const authAdapter = createAdapter<AuthState>()({
  setInProgress: (state) => ({
    ...state,
    status: AuthStatus.IN_PROGRESS,
  }),
  setUser: (state, payload) => ({
    ...state,
    status: AuthStatus.INIT,
    user: payload,
  }),
  reset: (state) => ({
    ...state,
    status: AuthStatus.INIT,
    user: null,
  }),
  selectors: {
    loading: (state) => state.status !== null && state.status === AuthStatus.IN_PROGRESS,
    user: (state) => state.user,
  },
});
