import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ChangePasswordRequestModel,
  ForgotPasswordRequestModel,
  LoginRequestModel,
  UserAttributes,
  VerifyOtpRequestModel,
} from '@escalate-backoffice/core/api-types';
import { ErrorStore } from '@escalate-backoffice/core/error-handler';
import { adapt } from '@state-adapt/angular';
import { getRequestSources, Source } from '@state-adapt/rxjs';
import { catchError, concatMap, delay, EMPTY, exhaustMap, filter, switchMap, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';
import { AuthStatus } from './auth-status.adapter';
import { authAdapter, AuthState } from './auth.adapter';

@Injectable({ providedIn: 'root' })
export class AuthStore {
  private authService = inject(AuthService);
  private storageService = inject(StorageService);
  private errorStore = inject(ErrorStore);
  private router = inject(Router);

  private initialState: AuthState = {
    status: AuthStatus.INIT,
    user: null,
    isLoggedIn: false,
  };

  pingUserSource$ = new Source<void>('[Auth] Fetch User');
  _pingUserRequest = getRequestSources(
    '[Auth] User Request',
    this.pingUserSource$.pipe(
      exhaustMap(() =>
        this.storageService.getToken().pipe(
          filter((token) => !!token),
          switchMap(() =>
            this.authService.fetchCurrentUser().pipe(
              catchError(() => {
                this.#store.reset();
                return EMPTY;
                // return of(err);

                // console.log(err);
              }),
            ),
          ),
        ),
      ),
    ),
  );

  saveUserSource$ = new Source<UserAttributes>('[Auth] Save Current User');
  _saveUserRequest = getRequestSources(
    '[Auth] Save Current User Request',
    this.saveUserSource$.pipe(
      exhaustMap((mapPayload) =>
        this.authService.saveCurrentUser(mapPayload.payload).pipe(
          catchError(() => {
            this.#store.reset();
            return EMPTY;
          }),
        ),
      ),
    ),
  );

  loginSource$ = new Source<LoginRequestModel>('[Auth] Login');
  private _loginRequest = getRequestSources(
    '[Auth] Login Request',
    this.loginSource$.pipe(
      tap(() => this.errorStore.resetErrors$.next({})),
      exhaustMap((mapPayloads) =>
        this.authService.sendLoginRequest(mapPayloads.payload).pipe(
          catchError(() => {
            this.#store.reset();
            return EMPTY;
            // return of(err);

            // console.log(err);
          }),
        ),
      ),
    ),
  );

  logoutSource$ = new Source<void>('[Auth] Logout');
  private _sendLogoutRequest = getRequestSources(
    '[Auth] Logout Request',
    this.logoutSource$.pipe(exhaustMap(() => this.authService.sendLogoutRequest())),
  );

  forgetPasswordSource$ = new Source<ForgotPasswordRequestModel>('[Auth] Send Reset OTP');
  private _sendForgetPasswordRequest = getRequestSources(
    '[Auth] Send Reset OTP Request',
    this.forgetPasswordSource$.pipe(
      // tap(() => this.errStore.resetErrs$.next({})),
      tap((mapPayloads) =>
        sessionStorage.setItem('forgetPasswordEmail', mapPayloads?.payload?.data?.attributes?.email ?? ''),
      ),
      concatMap((mapPayloads) =>
        this.authService.sendResetOtpRequest(mapPayloads.payload).pipe(
          catchError(() => {
            this.#store.reset();
            return EMPTY;
          }),
        ),
      ),
    ),
  );

  verifyOtpSource$ = new Source<VerifyOtpRequestModel>('[Auth] Send Verify OTP');
  protected _sendVerifyOtpRequest = getRequestSources(
    '[Auth] Send Verify OTP Request',
    this.verifyOtpSource$.pipe(
      concatMap((mapPayloads) =>
        this.authService.sendVerifyForgetPasswordOtpRequest(mapPayloads.payload).pipe(
          catchError(() => {
            this.#store.reset();
            return EMPTY;
          }),
        ),
      ),
    ),
  );

  changePasswordSource$ = new Source<ChangePasswordRequestModel>('[Auth] Change Password');
  protected _sendChangePasswordRequestSource = getRequestSources(
    '[Auth] Change Password Request',
    this.changePasswordSource$.pipe(
      concatMap((mapPayloads) =>
        this.authService.sendChangePasswordRequest(mapPayloads.payload).pipe(
          catchError(() => {
            this.#store.reset();
            return EMPTY;
          }),
        ),
      ),
    ),
  );

  deleteAuthTokenSource$ = new Source<void>('[Auth] Delete Token');

  readonly #store = adapt(this.initialState, {
    adapter: authAdapter,
    path: 'app.auth',
    sources: {
      setInProgress: [
        this.loginSource$,
        this.pingUserSource$,
        this.saveUserSource$,
        this.forgetPasswordSource$,
        this.verifyOtpSource$,
        this.changePasswordSource$,
      ],
      setUser: [this._pingUserRequest.success$, this._saveUserRequest.success$],
      reset: [this._sendLogoutRequest.success$, this.deleteAuthTokenSource$],
      noop: [
        this._loginRequest.success$.pipe(
          tap(({ payload }) => this.storageService.setToken(payload!)),
          delay(2000),
          tap(() => this.pingUserSource$.next()),
          delay(1000),
          tap(() => this.router.navigate(['/']).finally()),
        ),
        this._sendLogoutRequest.success$.pipe(
          tap(() => {
            this.deleteAuthTokenSource$.next();
            this.router.navigate(['/auth/login']).finally();
          }),
        ),
        this.deleteAuthTokenSource$.pipe(tap(() => this.storageService.removeToken())),
        this._sendForgetPasswordRequest.success$.pipe(tap(() => this.router.navigate(['/auth/otp/confirm']).finally())),
        this._sendVerifyOtpRequest.success$.pipe(tap(() => this.router.navigate(['/auth/change-password']).finally())),
        this._sendChangePasswordRequestSource.success$.pipe(
          tap(() => {
            sessionStorage.removeItem('forgetPasswordEmail');
            this.router.navigate(['/auth/login']).finally();
          }),
        ),
      ],
    },
  });

  vm$ = this.#store.state$;
  loading$ = this.#store.loading$;
  user$ = this.#store.user$;
}
