import {createAdapter} from '@state-adapt/core';

export enum AuthStatus {
    INIT = 'INIT',
    IN_PROGRESS = 'IN_PROGRESS',
}

export const authStatusAdapter = createAdapter<AuthStatus | null>()({
    loading: () => AuthStatus.IN_PROGRESS,
    loadingOff: () => AuthStatus.INIT,
});
