import { UserAttributes } from '@escalate-backoffice/core/api-types';
import { createAdapter } from '@state-adapt/core';

export const userAdapter = createAdapter<UserAttributes | null>()({
  setUser: (state, user) => ({
    ...state,
    ...user,
  }),
  resetUser: () => null,
});
