import { inject, Injectable } from '@angular/core';
import {
  ChangePasswordRequestModel,
  ChangePasswordResponseModel,
  ForgotPasswordRequestModel,
  ForgotPasswordResponseModel,
  LoginRequestModel,
  LoginResponseModel,
  LogoutResponseModel,
  UserAttributes,
  UserResponse,
  VerifyOtpRequestModel,
  VerifyOtpResponseModel,
} from '@escalate-backoffice/core/api-types';
import { API_CLIENT } from '@escalate-backoffice/core/http-client';
import { jwtDecode } from 'jwt-decode';
import { map, Observable, share, shareReplay, switchMap } from 'rxjs';
import { API_TOKEN } from './API_TOKEN';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  #api = inject(API_CLIENT);
  #apiToken = inject(API_TOKEN);

  // send login request
  sendLoginRequest(requestModel: LoginRequestModel): Observable<string | undefined> {
    return this.#api.sendCreateRequest<LoginResponseModel, LoginRequestModel>(`/auth/access_token`, requestModel).pipe(
      map((response) => {
        if (response.status === 'success') {
          return response.data?.attributes?.access_token;
        }

        return undefined;
      }),
    );
  }

  sendLogoutRequest(): Observable<LogoutResponseModel> {
    return this.#api.sendDeleteRequest<LogoutResponseModel>(`/auth/access_token`);
  }

  sendResetOtpRequest(requestModel: ForgotPasswordRequestModel): Observable<ForgotPasswordResponseModel> {
    return this.#api.sendCreateRequest<ForgotPasswordResponseModel, ForgotPasswordRequestModel>(
      `/auth/password`,
      requestModel,
    );
  }

  sendVerifyForgetPasswordOtpRequest(requestModel: VerifyOtpRequestModel): Observable<VerifyOtpResponseModel> {
    return this.#api.sendCreateRequest<VerifyOtpResponseModel, VerifyOtpRequestModel>(`/auth/password/verify-otp`, {
      data: {
        attributes: {
          otp: String(requestModel.data.attributes.otp),
        },
      },
    });
  }

  sendChangePasswordRequest(requestModel: ChangePasswordRequestModel): Observable<ChangePasswordResponseModel> {
    return this.#api.sendUpdateRequest<ChangePasswordResponseModel, ChangePasswordRequestModel>(
      `/auth/password`,
      requestModel,
    );
  }

  fetchCurrentUser(xUserId: string | number | null = null): Observable<UserAttributes | null | undefined> {
    return this.#apiToken.pipe(
      map((token) => jwtDecode(token!)['sub']),
      map((userId) => (xUserId ? xUserId : userId)),
      switchMap((userId) =>
        this.#api.sendReadRequest<UserResponse | null>(`/users/${userId}`).pipe(map((user) => user?.data?.attributes)),
      ),
      shareReplay(),
    );
  }

  saveCurrentUser(attributes: UserAttributes): Observable<UserAttributes | null | undefined> {
    return this.#apiToken.pipe(
      map((token) => jwtDecode(token!)['sub']),
      switchMap((userId) =>
        this.#api
          .sendUpdateRequest<UserResponse | null, UserAttributes>(`/users/${userId}`, attributes)
          .pipe(map((user) => user?.data?.attributes)),
      ),
      share(),
    );
  }
}
