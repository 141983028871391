import { inject, Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { LOCAL_STORAGE } from './LOCAL_STORAGE';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  #storage = inject(LOCAL_STORAGE);

  getToken(): Observable<string | null> {
    const data = this.#storage.getItem('jwtToken');
    if (data) {
      return of(data);
    }
    return of(null);
  }

  hasToken(): Observable<boolean> {
    return this.getToken().pipe(map((value) => !!value));
  }

  setToken(data: string): Observable<string> {
    this.#storage.setItem('jwtToken', data);
    return of(data);
  }

  removeToken(): Observable<boolean> {
    this.#storage.removeItem('jwtToken');
    return of(true);
  }
}
